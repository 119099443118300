<template>
  <div class="meeting-detail">
    <van-tabs
      @change="tabChanges"
      lazy-render
      swipeable
      :ellipsis="false"
      sticky
      v-model="active"
      v-if="status != 1"
    >
      <van-tab title="会议内容">
        <div v-if="info && userInfo">
          <div class="head-top-bg" />
          <meeting-summary
            :info="info"
            :userInfo="userInfo"
            :id="id"
            :type="type + ''"
          ></meeting-summary>
        </div>
      </van-tab>
      <van-tab :title="`参会私董(${joinNum})`">
        <div v-if="info && userInfo">
          <meeting-director
            :info="info"
            :userInfo="userInfo"
            :id="id"
            :type="type + ''"
          ></meeting-director>
        </div>
      </van-tab>
      <van-tab title="改进评判">
        <div v-if="info && userInfo">
          <meeting-argument
            :meetingInfo="info"
            :userInfo="userInfo"
            :id="id"
            :type="type + ''"
          ></meeting-argument>
        </div>
      </van-tab>
      <van-tab title="提供帮助">
        <div v-if="info && userInfo" style="background-color: #f7f7f7">
          <offer-help
            :info="info"
            :userInfo="userInfo"
            :id="id"
            :type="type + ''"
          ></offer-help>
        </div>
      </van-tab>
      <van-tab :title="`参会感想(${feelingNum})`">
        <div v-if="info && userInfo" style="background-color: #f7f7f7">
          <meeting-feeling
            :info="info"
            :userInfo="userInfo"
            :id="id"
            :user_id="info.user.id + ''"
            :type="type + ''"
          ></meeting-feeling>
        </div>
      </van-tab>
    </van-tabs>
    <div v-else>
      <meeting-summary
        v-if="info && userInfo"
        :info="info"
        :userInfo="userInfo"
        :id="id"
        :type="type + ''"
      ></meeting-summary>
    </div>
    <icy-backhome v-if="showBottom" :background="backHomeStyle"></icy-backhome>
  </div>
</template>

<script>
// 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type
// status 0 => "待付款"  1 => "已取消" 2 => '待召开', 3 => '会议中', 4 => '会后反馈', 5 => '已完成'
export default {
  data() {
    return {
      active: 0,
      type: "",
      id: "",
      joinNum: 0,
      info: null,
      feelingNum: 0,
      userInfo: null,
      hostInfo: null,
      showBottom: false,
      status: "",
    };
  },
  created() {
    this.$title("会议详情");
    this.initPage();
  },
  mounted() {
    this.$bus.$on("updateMeeting", () => {
      this.initPage();
    });
  },
  computed: {
    backHomeStyle() {
      return [1, 2, 4].includes(this.active)
        ? /*'#f7f7f7'*/ "transparent"
        : "transparent";
    },
  },
  methods: {
    async initPage() {
      this.type = parseInt(this.$route.query.type) + "" || "";
      this.id = parseInt(this.$route.query.id) + "" || "";
      this.active = parseInt(this.$route.query.active) || 0;
      this.status = this.$route.query.status;
      await this.initInfo();
      await this.initUserInfo();
      this.initFeelingNum();
      this.initShare();
      this.showBottom = true;
    },
    // 主持人指定分享
    initShare() {
      let meetingInfo = this.info;
      let meetingId = this.id;
      let hostUserInfo = null;
      if (meetingInfo && meetingInfo.host_user) {
        hostUserInfo = meetingInfo.host_user;
      }
      let field = "";
      if (this.info.field) {
        field = this.info.field.name;
      }
      const shareLink =
        location.origin +
        location.pathname +
        `#/invite?meetingId=${meetingId}&hostUserId=${hostUserInfo.id}`;
      this.shareLink = shareLink;
      let questionInfo = `我的困惑是如何「${meetingInfo.questions[0]}」这对「${meetingInfo.questions[1]}」很重要，我针对这个问题已做了「${meetingInfo.questions[2]}」，我希望帮我「 ${meetingInfo.questions[3]}」`;

      const shareInfo = {
        title: `${
          hostUserInfo.name || hostUserInfo.phone
        }主持的${field}私董会邀请你的参加`,
        desc: `${questionInfo}`,
        // 朋友圈标题描述
        longTitle: `${questionInfo}`,
        link: shareLink,
        imgUrl: [location.origin + "/share-icon1.png"],
      };

      this.$bus.$emit("hasShare", shareInfo);
    },
    tabChanges(e) {
      this.$router.replace({ query: { ...this.$route.query, active: e } });
    },
    async initInfo() {
      const resp = await this.$API
        .get("meetingDetail", { id: this.id })
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.info = resp.data;
        this.joinNum = resp.data.meeting_user_count;
      }
    },
    async initUserInfo() {
      const resp = await this.$API.get("userInfo", false).then((resp) => resp);
      if (resp.code == 1000) {
        this.userInfo = resp.data.info;
      }
    },
    async initFeelingNum() {
      const data = {
        id: this.info.id,
      };
      const resp = await this.$API
        .get("joinFeelingList", data)
        .then((resp) => resp);
      if (resp.code == 1000) {
        this.feelingNum = resp.data.list.length;
      }
    },
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.head-top-bg {
  height: 0.2rem;
  background-color: #f6f6f6;
}
</style>
